<template>
  <div>
    <div id="privacy-policy-page" class="flex-column">
      <div class="flex-center width-1000 body-2">
        <div>
          <h1 class="h1-title">Data Security</h1>
        </div>

        <div>
          <h3>Overview</h3>
          <p>
            Xphase Pty Ltd intentions for publishing a Data Security Policy are not to impose restrictions that are
            contrary to Xphase established culture of openness, trust, and integrity. Xphase is committed to protecting
            our employees, partners, and the company from illegal or damaging actions by individuals, either knowingly
            or unknowingly.
          </p>
          <p>
            Effective security is a team effort involving the participation and support of every Xphase employee and
            affiliate who deals with information and/or information systems. It is the responsibility of every computer
            user to know these guidelines, and to conduct their activities accordingly. Security procedures are sought
            from many external sources on an ongoing basis to stay current with new technologies and systems.
          </p>

          <h4>Purpose</h4>
          <p>
            This policy outlines the data security controls in place at Xphase for the purpose of protecting Xphase,
            Xphase's employees, customers, and partners. A lack of such controls exposes Xphase to risks including
            compromise of network systems and services and legal issues.
          </p>

          <h4>Scope</h4>
          <p>
            This policy applies to employees, contractors, consultants, and other workers at Xphase, including all
            personnel affiliated with third parties. This policy applies to all data stored or processed by equipment
            that is owned or leased by Xphase.
          </p>

          <h3>Policy</h3>
          <h4>Data Access</h4>
          <p>For details regarding Xphase's access control procedures, please refer to our Access Control Policy.</p>
          <h4>Data Protection</h4>
          <p>
            Xphase has several controls in place to mitigate the risk of unauthorized usage and exfiltration of company
            data.
          </p>
          <ol>
            <li>
              Data loss prevention (DLP) software is installed on all company owned laptops and servers. The DLP
              solution is configured to prevent the unauthorized exfiltration of the following types of data to
              non-approved sources such as personal email addresses:
            </li>
            <li>
              <ol>
                <li>Social Security Numbers</li>
                <li>Payment Card Numbers</li>
                <li>Employer Identification Numbers</li>
                <li>Internal documents tagged as Top Secret</li>
                <li>Customer Addresses</li>
              </ol>
            </li>
            <li>
              All access to company devices must be done using company owned devices, or personal devices with Xphases's
              Mobile Device Management profiles installed. Authentication to Xphase Virtual Private Network (VPN) is
              only limited to these devices via digital certificates. Attempting to authenticate with other devices will
              result in a failure.
            </li>
            <li>
              All access to databases in the Production environment are logged and monitored using Xphase’s Security
              Information and Event Management solution. Alarms have been configured to flag the Security team about
              anomalous database behavior.
            </li>
            <li>
              All company owned laptops, desktops, and mobile devices are encrypted using Xphases’s MDM solution. In the
              event of a theft or loss of an asset, the Information Technology department use the MDM solution to
              perform a remote wipe to prevent the risk of unauthorized access to any stored company data.
            </li>
            <li>
              All databases in the Production environment are required to be encrypted at rest at the volume level.
            </li>
          </ol>

          <h4>Data Classification</h4>
          <p>For details regarding Xphases’s data classification scheme, please see our Data Classification Policy.</p>

          <h4>Enforcement</h4>
          <p>
            Any employee found to have violated this policy may be subject to disciplinary action, up to and including
            termination of employment.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataSecurity"
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
@import "@/scss/v2/legal.scss";

#privacy-policy-page {
  padding-top: $PADDING_X2_05;
}
</style>
